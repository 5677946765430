import React, { useState, useContext } from "react";
import StyleContext from "../../contexts/StyleContext";
import "./ToggleSwitch.css";
const ToggleSwitch = () => {
  const [isChecked, setChecked] = useState(false);
  const styleContext = useContext(StyleContext);
  useState(() => {
    styleContext.changeTheme();
  });
  return <label className="switch"></label>;
};
export default ToggleSwitch;
