/* Change this file to get your personal Portfolio */

// Your Summary And Greeting Section

import emoji from "react-easy-emoji";

const greeting = {
  /* Your Summary And Greeting Section */
  title: "Hi all I'm Harsha",
  subTitle: emoji(
    "A passionate Software Developer 🚀 having an experience of building Web applications with JavaScript / Reactjs / Nodejs and some other cool libraries and frameworks."
  ),
  resumeLink:
    "https://drive.google.com/file/d/1zm4j7VuL-hsIXTEsCcSWcaW1W6TNJLzW/view?usp=sharing",
};

// Your Social Media Link

const socialMediaLinks = {
  github: "https://github.com/harsha-iiiv",
  linkedin: "https://www.linkedin.com/in/harshasurisetty/",
  gmail: "dev.harsha.surisetty@gmail.com",
  twitter: "https://twitter.com/Harshasurisetty",
  facebook: "https://www.linkedin.com/in/harsha-surisetty/",
};

// Your Skills Section

const skillsSection = {
  title: "What i do",
  subTitle: "I possess an innate curiosity, thrive on tackling intriguing challenges, and excel in problem-solving with a strong aptitude for learning.",
  skills: [
    emoji("⚡ Develop Real-time and scalable application"),
    emoji(
      "⚡ Develop highly interactive Front end / User Interfaces for your web."
    ),
    emoji(
      "⚡ Integration of third party services such as Firebase/ AWS / Digital Ocean"
    ),
  ],

  /* Make Sure You include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5",
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt",
    },
    {
      skillName: "Amazon Alexa skills",
      fontAwesomeClassname: "fab fa-amazon",
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js",
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react",
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node",
    },
    {
      skillName: "git",
      fontAwesomeClassname: "fab fa-git",
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm",
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database",
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws",
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python",
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker",
    },
  ],
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Backend",
      progressPercentage: "90%",
    },
    {
      Stack: "Programming",
      progressPercentage: "85%",
    },
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "50%", //Insert relative proficiency in percentage
    },
  ],
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */
const openSource = {
  githubConvertedToken: btoa("370518135a0ae2212469b9be7bffbda479c058b3"),
  githubUserName: "harsha-iiiv",
};
// Some Big Projects You have worked with your company

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle: "Discuss a project or just want to say hi my inbox is open for all",
  number: "+91-9704946992",
  email_address: "dev.harsha.surisetty@gmail.com",
};

export {
  greeting,
  socialMediaLinks,
  skillsSection,
  techStack,
  openSource,
  contactInfo,
};
